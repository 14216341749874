<template>
    <div>
        <LiefengModal
            title="员工管理"
            :value="controlForm.formFlag"
            :fullscreen="false"
            :width="600"
            :height="600"
            @input="closeModal"
        >
            <template v-slot:contentarea>
                <div class="container">
                    <div class="content">
                        <Form
                            :model="formData"
                            :label-colon="true"
                            :label-width="100"
                            :rules="formDataValidate"
                            ref="form">
                            <FormItem label="所属栏目" prop="staffTypeId">
                                <Select v-model="formData.staffTypeId" style="width:300px;" placeholder="请选择员工类型">
                                    <Option v-for="(item, idx) in staffTypes" :value="item.staffTypeId" :key="idx">{{item.name}}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="头像" prop="avatarUrl">
                                <LiefengUpload accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" :target="1" :defaultList='formData.avatarUrl' :showView="true" @success="onSuccessUpload(arguments, 'avatarUrl')"></LiefengUpload>
                            </FormItem>
                            <FormItem label="姓名" prop="userName">
                                <Input style="width:300px" v-model.trim="formData.userName" :maxlength="50"
                                    placeholder="请填写姓名"></Input>
                            </FormItem>
                            <FormItem label="联系电话" prop="phone">
                                <Input style="width:300px" v-model.trim="formData.phone" :maxlength="50"
                                    placeholder="请填写联系电话" :disabled="!!detailId"></Input>
                            </FormItem>
                            <FormItem label="登陆密码" :prop="detailId ? '' : 'password'">
                                <Input style="width:300px" type="password" v-model.trim="formData.password" :maxlength="50"
                                    placeholder="请填写登陆密码"></Input>
                            </FormItem>
                            <FormItem label="确认密码">
                                <Input style="width:300px" type="password" v-model.trim="formData.confirm_password" :maxlength="50"
                                    placeholder="请填写确认密码"></Input>
                            </FormItem>
                            <FormItem label="证件类型" prop="idNumType">
                                <Select v-model="formData.idNumType" style="width:300px;" :disabled="!!detailId" placeholder="请选择证件类型">
                                    <Option value="1">内地身份证</Option>
                                    <Option value="2">港澳台身份证</Option>
                                    <Option value="3">国外护照</Option>
                                    <Option value="4">其他</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="证件号码" prop="idNum">
                                <Input style="width:300px" v-model.trim="formData.idNum" :maxlength="50"
                                    placeholder="请填写证件号码" :disabled="!!detailId"></Input>
                            </FormItem>
                            <FormItem label="通讯地址" prop="addr">
                                <Input style="width:300px" v-model.trim="formData.addr" :maxlength="50"
                                    placeholder="请填写通讯地址"></Input>
                            </FormItem>
                            <FormItem label="民族" prop="national">
                                <Input style="width:300px" v-model.trim="formData.national" :maxlength="50"
                                    placeholder="请填写民族"></Input>
                            </FormItem>
                            <FormItem label="籍贯" prop="nativePlace">
                                <Input style="width:300px" v-model.trim="formData.nativePlace" :maxlength="50"
                                    placeholder="请填写籍贯"></Input>
                            </FormItem>
                            <FormItem label="紧急联络人" prop="contact">
                                <Input style="width:300px" v-model.trim="formData.contact" :maxlength="50"
                                    placeholder="请填写紧急联络人"></Input>
                            </FormItem>
                            <FormItem label="联络人电话" prop="contactMobile">
                                <Input style="width:300px" v-model.trim="formData.contactMobile" :maxlength="50"
                                    placeholder="请填写联络人电话"></Input>
                            </FormItem>
                            <FormItem label="部门编码" prop="deptCode">
                                <Input style="width:300px" v-model.trim="formData.deptCode" :maxlength="50"
                                    placeholder="请填写部门编码"></Input>
                            </FormItem>
                            <FormItem label="员工类型" prop="staffTypeId">
                                <Select v-model="formData.staffTypeId" style="width:300px;" placeholder="请选择员工类型">
                                    <Option v-for="(item, idx) in staffTypes" :value="item.staffTypeId" :key="idx">{{item.name}}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="文化程度" prop="eduDegree">
                                <Select v-model="formData.eduDegree" style="width:300px;" placeholder="请选择文化程度">
                                    <Option value="0">未知</Option>
                                    <Option value="1">小学</Option>
                                    <Option value="2">初中</Option>
                                    <Option value="3">高中</Option>
                                    <Option value="4">大学</Option>
                                    <Option value="5">硕士</Option>
                                    <Option value="6">博士</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="入职日期" prop="entryDate">
                                <DatePicker style="width:300px" :value="formData.entryDate" type="datetime" placeholder="选择日期" @on-change="formData.entryDate = $event"></DatePicker>
                            </FormItem>
                            <FormItem label="离职日期" prop="leftDate">
                                <DatePicker style="width:300px" :value="formData.leftDate" type="datetime" placeholder="选择日期" @on-change="formData.leftDate = $event"></DatePicker>
                            </FormItem>
                            <FormItem label="曾用名" prop="formerName">
                                <Input style="width:300px" v-model.trim="formData.formerName" :maxlength="50"
                                    placeholder="请填写曾用名"></Input>
                            </FormItem>
                            <FormItem label="oem编码" prop="oemCode">
                                <Input style="width:300px" v-model.trim="formData.oemCode" :maxlength="50"
                                    placeholder="请填写oem编码"></Input>
                            </FormItem>
                            <FormItem label="组织机构编码" prop="orgCode">
                                <Input style="width:300px" v-model.trim="formData.orgCode" :maxlength="50"
                                    placeholder="请填写组织机构编码"></Input>
                            </FormItem>
                            <FormItem label="办公电话" prop="tel">
                                <Input style="width:300px" v-model.trim="formData.tel" :maxlength="50"
                                    placeholder="请填写办公电话"></Input>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right:10px" @click="closeModal(false)">取消</Button>
                <Button type="primary" @click="saveData">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
import LiefengUpload from '@/components/LiefengUpload';

export default {
    components: {
        LiefengModal, LiefengUpload
    },

    data() {
        return {
            controlForm: {
                tenantList: [],
                defaultListLogo:[],
                defaultListImage:[],
                id:'1',
                formFlag: false,
                timer:'',
                fatherSystemList: '',
            },

            formData: {
                password: "88888888",
                phone: "",
                userName: "",
                idNumType: "",
                idNum: ""
            },
            
            formDataValidate: {
                // userName: [
                //     { required: true, message: '姓名不能为空', trigger: 'blur' }
                // ],
                // password: [
                //     { required: true, message: '密码不能为空', trigger: 'blur' }
                // ],
                // confirm_password: [
                //     { required: true, message: '确认密码不能为空', trigger: 'blur' }
                // ],
                // phone: [
                //     { required: true, message: '联系电话不能为空', trigger: 'blur' }
                // ],
                // idNumType: [
                //     { required: true, message: '证件类型不能为空', trigger: 'blur' }
                // ],
                // idNum: [
                //     { required: true, message: '证件号码不能为空', trigger: 'blur' }
                // ]
            },
            
            detailId: null,

            // 员工类型数组
            staffTypes: null
        }
    },

    created() {
        this.getStaffTypes()
    },

    methods: {
        open(id) {
            id && this.getDetail(id)
            this.detailId = id
            this.closeModal(true)
        },

        getStaffTypes() {
            this.$get('http://liefeng-gateway.test.gongxiangplat.com/api/auth/pc/staff/queryStaffTypes', {
                oemCode: parent.vue.oemInfo.oemCode,
                appCode: 'suiyue_pc'
            }).then(res => {
                if (res.code == 200) {
                    this.staffTypes = res.dataList
                } else {
                    this.$Message.error({
                        content:'获取商品列表失败',
                        background:true
                    })
                }
            })
        },

        saveData() {
            this.$refs.form.validate((valid)=> {
                if (valid) {
                    let formData = this.formData

                    if (formData.password) {
                        if (!formData.confirm_password) {
                            return this.$Message.error({
                                content: "请输入确认密码",
                                background: true
                            })
                        }

                        if (formData.password !== formData.confirm_password) {
                            return this.$Message.error({
                                content: "密码与确认密码不相同，请检查后重试！",
                                background: true
                            })
                        }
                    }

                    if (this.detailId) {
                        delete formData.gmtCreate
                        delete formData.gmtModified
                        delete formData.idNum

                        this.clearEmptyField(formData)
                    }

                    this.$post(`http://liefeng-gateway.test.gongxiangplat.com/api/auth/pc/staff/${this.detailId ? `update` : 'add'}`, {
                        staffId: this.detailId,
                        ...formData,
                    }).then(res => {
                        if (res.code == 200) {

                            if (this.detailId || res.data == "2") {
                                this.$Message.success({
                                    content: this.detailId ? '更新成功' : '提交成功',
                                    background:true
                                })
                                this.closeModal()
                                return
                            }

                            let msg

                            switch (res.data) {
                                case "1":
                                    msg = "身份证号已存在"
                                    break;
                                case "3":
                                    msg = "该手机号已绑定其他身份证"
                                    break;
                                case "4":
                                    msg = "该手机号已存在"
                                    break;
                            }

                            this.$Message.error({
                                content: msg,
                                background: true
                            })
                            
                        } else {
                            this.$Message.error({
                                content: res.desc,
                                background: true
                            })
                        }
                    })
                }
            })
        },

        getDetail(id) {
            this.$get('http://liefeng-gateway.test.gongxiangplat.com/api/auth/pc/staff/queryById', {
                staffId: id
            }).then(res =>{
                if (res.code == 200) {
                    let data = res.data

                    data.entryDate && (data.entryDate = this.formatDate(new Date(data.entryDate), "yyyy-MM-dd hh:mm:ss"))
                    data.leftDate && (data.leftDate = this.formatDate(new Date(data.leftDate), "yyyy-MM-dd hh:mm:ss"))

                    console.log(data)

                    this.formData = data
                } else {
                    this.$Message.error({
                        content:'获取详情失败',
                        background:true
                    })
                }
            })
        },

        closeModal(flag) {
            //关闭弹窗
            this.controlForm.formFlag = flag;
            //清空表单
            Object.assign(this.$data.formData, this.$options.data().formData);

            // 重置表单字段 Form
            this.$refs['form'].resetFields();

            this.showUploadLogo = false;
            this.showUploadImage = false;
            this.$nextTick(()=>{this.showUploadLogo = true; this.showUploadImage = true;})
            if (this.tenantId) {
                this.formData.tenantId = this.tenantId
                this.$("#tenantIdItem").hide();
            }
            this.controlForm.showMenu = "basicInfo"
            //父页面刷新列表
            this.$emit('resetBtn')
        },

        /**
         * 监听图片上传成功事件
         */
        onSuccessUpload(e, key) {
            this.formData[key] = e[1].url
        },

        clearEmptyField(obj) {
            for (const key in obj) {
                if (!obj[key]) delete obj[key]
            }
        },

        formatDate(date, fmt) {
            let o = {
                "M+": date.getMonth() + 1, //月份
                "d+": date.getDate(), //日
                "h+": date.getHours(), //小时
                "m+": date.getMinutes(), //分
                "s+": date.getSeconds(), //秒
                "q+": Math.floor((date.getMonth() + 3) / 3), //季度
                "S": date.getMilliseconds() //毫秒
            }
            if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))
            for (let k in o)
                if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[
                    k]).substr(("" + o[k]).length)))
            return fmt
        }
    }
}
</script>

<style lang="less" scoped>

.securityDiv {
  border-width:0px;
  margin-left: 20px;
  width:72px;
  height:24px;
  display:flex;
  font-family:'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
  font-weight:650;
  font-style:normal;
  color:#10BC92;
}

.container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 123px);
  /deep/.menu {
    height: 100%;
    .ivu-menu {
      height: 100%;
      width: 220px !important;
    }
  }
  .content {
    overflow: auto;
    padding-top: 20px;
    flex: 1;
    height: 100%;
  }
}

/deep/ .validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ #modal_contentarea{
  overflow: inherit !important;
}
</style>